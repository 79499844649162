import request from "@/utils/http/requset";

export function rate(data) {
  return request({
    url: "/manage/merchant/pay/rate",
    method: "post",
    ContentType: "application/json;charset=UTF-8",
    data: data
  });
}
