<template>
  <section>
    <!--工具条-->
    <el-form :inline="true" class="toolbar" :model="searchForm">
      <el-form-item label="最近">
        <el-input
          v-model="searchForm.minNun"
          size="mini"
          clearable
          @input="changeminNum()"
        ></el-input>
      </el-form-item>


      <el-form-item>
        <span style="margin-right: 30px">分钟</span><el-button @click="search" type="primary" size="mini">查询</el-button>
      </el-form-item>
    </el-form>

    <el-table
      :data="tableData"
      border
      height="650"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column align="center" prop="orderValue" label="排序"></el-table-column>
      <el-table-column align="center" prop="payChannel" label="渠道"></el-table-column>
      <el-table-column align="center" prop="successRate" label="成功率">
        <template slot-scope="scope">
          <span>{{scope.row.successRate + '%'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="urlAvg" label="拉起(秒)"></el-table-column>
      <el-table-column align="center" prop="openSuccessRate" label="拉起成功率">
        <template slot-scope="scope">
          <span>{{scope.row.openSuccessRate + '%'}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="backAvg" label="充值成功(秒)"></el-table-column>
      <el-table-column align="center" prop="payCnt" label="充值次数"></el-table-column>
      <el-table-column align="center" prop="processCnt" label="支付中"></el-table-column>
      <el-table-column align="center" prop="successCnt" label="成功数"></el-table-column>
      <el-table-column align="center" prop="failCnt" label="失败数"></el-table-column>
    </el-table>
    <el-pagination
      class="pager"
      @size-change="pageSizeChange"
      @current-change="pageIndexChange"
      :current-page="pageIndex"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="dataTotal"
    ></el-pagination>
  </section>
</template>

<script>
import {rate} from "@/api/merchantPayConfig/merchantPayRate";
import DataUtil from "../../utils/config/dataUtil";
import Enum from "../../utils/Enum/Enum";
export default {
  data() {
    return {
      pageIndex: 1,
      pageSize: 30,
      dataTotal: 0,
      loading: false,
      tableData: [],
      statusList: Enum.orderStatus,
      searchForm: {
        minNun: 5
      },
    };
  },
  created() {
    this.getList();
  },
  mounted() {

  },
  methods: {
    getList(){
      this.loading = true;
      rate({
        content: {minute: this.searchForm.minNun}
      }).then(res => {
        if (res.code === "0000"){
          this.loading = false;
          this.tableData = res.data;
        }
      });
    },

    changeminNum(){
      if (this.searchForm.minNun > 24 * 60){
        this.searchForm.minNun = 24 * 60;
        this.$message.warning("查询时间不能超过1天");
      }
    },

    dateTransform(date) {
      return DataUtil.dateTransform(date);
    },
    getOrderStatus(val) {
      return Enum.getOrderStatus(val);
    },
    search() {
      this.pageIndex = 1;
      this.getList();
    },

    // 分页
    pageSizeChange(val) {
      this.pageSize = val;
      this.pageIndex = 1;

      // this.bindGrid();
    },
    pageIndexChange(val) {
      this.pageIndex = val;

      // this.bindGrid();
    },
  },
  components: {},
};
</script>

<style lang="scss">
@import "../../assets/css/base";
.el-form-item {
  margin-bottom: 10px;
}
</style>
